import nationalities from '@/api/nationalities';

export default {
  methods: {
    async getNationalities() {
      try {
        const result = await nationalities.getNationalities();
        this.$store.commit('nationalities/setNationalities', result);
        return Promise.resolve();
      } catch ({ response }) {
        const message = new Response(response).getError();
        this.$store.commit('nationalities/setNationalitiesError', message);
        return Promise.reject();
      }
    },
  },
};
