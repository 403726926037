<template>
  <validation-observer v-slot="{ errors: ratingErrors }" tag="div">
    <div
      class="form-validation"
      :class="{ 'is-invalid': hasErrors(ratingErrors) }"
    >
      <ul class="list-unstyled">
        <validation-provider
          :ref="$t('Originality')"
          class="star-rating row"
          :name="$t('Originality')"
          rules=""
          tag="li"
        >
          <div class="col-12 col-sm-4">
            <h6>{{ $t('Originality') }}:</h6>
          </div>
          <div class="col-12 col-sm-8">
            <star-rating v-model="form.rateOriginality" name="originality" />
          </div>
        </validation-provider>

        <validation-provider
          :ref="$t('Plot')"
          class="star-rating row"
          :name="$t('Plot')"
          rules=""
          tag="li"
        >
          <div class="col-12 col-sm-4">
            <h6>{{ $t('Plot') }}:</h6>
          </div>
          <div class="col-12 col-sm-8">
            <star-rating v-model="form.ratePlot" name="plot" />
          </div>
        </validation-provider>

        <validation-provider
          :ref="$t('Writing style')"
          class="star-rating row"
          :name="$t('Writing style')"
          rules=""
          tag="li"
        >
          <div class="col-12 col-sm-4">
            <h6>{{ $t('Writing style') }}:</h6>
          </div>
          <div class="col-12 col-sm-8">
            <star-rating v-model="form.rateWritingStyle" name="writing-style" />
          </div>
        </validation-provider>

        <validation-provider
          :ref="$t('Emotion')"
          class="star-rating row"
          :name="$t('Emotion')"
          rules=""
          mode="aggressive"
          tag="li"
        >
          <div class="col-12 col-sm-4">
            <h6>{{ $t('Emotion') }}:</h6>
          </div>
          <div class="col-12 col-sm-8">
            <star-rating v-model="form.rateEmotion" name="emotion" />
          </div>
        </validation-provider>
      </ul>
      <span
        v-if="hasErrors(ratingErrors)"
        class="error-message"
        v-text="getErrors(ratingErrors)[0]"
      ></span>
    </div>
  </validation-observer>
</template>

<script>
import StarRating from '@/components/ui/starRating/StarRating';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: {
    StarRating,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    book: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        rateOriginality: 0,
        ratePlot: 0,
        rateWritingStyle: 0,
        rateEmotion: 0,
      },
      errors: {},
    };
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form);
      },
    },

    book: {
      immediate: true,
      handler() {
        this.updateForm();
      },
    },
  },

  methods: {
    updateForm() {
      return Object.keys(this.form).map((property) => {
        return { [property]: this.book[property] || this.form[property] };
      });
    },

    getErrors(errors) {
      return Object.values(errors).flat();
    },

    hasErrors(errors) {
      return Object.values(errors).flat().length;
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './styles.scss';
</style>
