<template>
  <div
    class="t-small t-red leading-tight t-italic"
    :class="{ 'is-active': isActive && show }"
    v-text="error"
  ></div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: undefined,
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isActive() {
      return this.errors instanceof Array && this.errors.length > 0;
    },

    error() {
      return this.isActive ? this.errors[0] : '';
    },
  },
};
</script>
