<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="form-group nationalities-list"
    :name="validationField"
    :mode="validationMode"
    :rules="validationRules"
    tag="div"
  >
    <slot name="label"></slot>
    <Singleselect
      :value="value"
      :options="options"
      :taggable="false"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <slot name="error">
      <validation-error class="ml-2 mt-1" :errors="errors" />
    </slot>
  </ValidationProvider>
</template>

<script>
import Singleselect from '@/components/ui/singleselect/SingleSelect';
import { ValidationProvider } from 'vee-validate';
import ValidationError from '@/components/validation/ValidationError';

export default {
  name: 'NationalitySelect',
  components: {
    Singleselect,
    ValidationProvider,
    ValidationError,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
      required: true,
    },
    validationField: {
      type: String,
      default: undefined,
    },

    validationRules: {
      type: String,
      default: '',
    },

    validationMode: {
      type: String,
      default: 'aggressive',
    },
  },
};
</script>
