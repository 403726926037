import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=d290f61a&"
import script from "./Description.vue?vue&type=script&lang=js&"
export * from "./Description.vue?vue&type=script&lang=js&"
import style0 from "./Description.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Fviews%2Freviews%2Fcreate%2Fdescription%2FDescription.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports