<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="form-group"
    :mode="mode"
    :name="name"
    :rules="rules"
    tag="div"
  >
    <v-date-picker
      v-model="selected"
      v-bind="$attrs"
      class="custom-calendar"
      name="datepicker"
      :value="value"
      :input-props="{
        class: 'custom-date-picker',
        placeholder,
      }"
      v-on="$listeners"
    ></v-date-picker>
    <slot name="error">
      <validation-error class="ml-2 mt-1" :errors="errors" />
    </slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import ValidationError from '../validation/ValidationError';

export default {
  name: 'Calendar',
  components: {
    ValidationError,
    ValidationProvider,
  },
  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'eager',
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },
  },
};
</script>

<style lang="scss">
@import './calendar.scss';
</style>
