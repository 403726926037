<template>
  <div class="form-check d-inline-block">
    <label class="form-check__label mr-2 d-flex align-items-center">
      <input
        class="form-check__input"
        type="checkbox"
        :checked="isChecked"
        :value="value"
        tabindex="1"
        @change="updateCheckbox"
      />
      <span class="form-check__indicator" tabindex="0"></span>
      <span>
        {{ label }}
        <span v-if="limit" class="t-normal t-small"
          >({{ $t('up to') }} {{ limit }})</span
        >
      </span>
    </label>
  </div>
</template>

<i18n src="./translations.json"></i18n>

<script>
export default {
  name: 'BasicCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: { type: String, required: true },
    modelValue: { default: '', type: [Boolean, String] },
    label: { type: String, required: true },
    trueValue: { default: true, type: Boolean },
    falseValue: { default: false, type: Boolean },
    limit: { default: 0, type: Number },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },
  },
  emits: ['change'],
  methods: {
    updateCheckbox(event) {
      const isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
@import '@/assets/styles/_inputs.scss';
</style>
