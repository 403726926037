import { render, staticRenderFns } from "./BasicCheckbox.vue?vue&type=template&id=91ca2854&scoped=true&"
import script from "./BasicCheckbox.vue?vue&type=script&lang=js&"
export * from "./BasicCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./BasicCheckbox.vue?vue&type=style&index=0&id=91ca2854&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ca2854",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Fcomponents%2Fui%2Fbasic-checkbox%2FBasicCheckbox.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports