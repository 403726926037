<template>
  <div class="alert alert-danger" role="alert">
    <p class="mb-0" v-text="message"></p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-danger {
  color: #c05621;
  background-color: #fffaf0;
}
</style>
