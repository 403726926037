<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="form-group"
    :class="{ 'is-active': isActive || value, 'is-empty': !value }"
    :mode="mode"
    :name="name"
    :rules="rules"
    tag="div"
  >
    <label v-if="label" class="form-label" :for="id" v-text="label"></label>
    <TextField
      :id="id"
      class="form-control"
      :value="value"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :aria-label="label ? undefined : name"
      :placeholder="placeholder"
      :type="type"
      @input="(val) => $emit('input', val)"
      @focus="isActive = true"
      @deactivate="isActive = false"
    />
    <slot name="error">
      <validation-error class="ml-2 mt-1" :errors="errors" />
    </slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import { integer } from 'vee-validate/dist/rules';
import ValidationError from './ValidationError';
import TextField from './TextField';

extend('integer', integer);

export default {
  name: 'TextFieldWithValidation',

  components: {
    ValidationError,
    ValidationProvider,
    TextField,
  },

  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      default: undefined,
    },

    id: {
      type: String,
      default: undefined,
    },

    label: {
      type: String,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    name: {
      type: String,
      default: undefined,
    },

    type: {
      type: String,
      default: 'text',
    },

    value: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: 'eager',
    },

    rules: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isActive: false,
    };
  },
};
</script>
