import { render, staticRenderFns } from "./MultiselectWithValidation.vue?vue&type=template&id=343bb292&"
import script from "./MultiselectWithValidation.vue?vue&type=script&lang=js&"
export * from "./MultiselectWithValidation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Fcomponents%2Fvalidation%2FMultiselectWithValidation.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports