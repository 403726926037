export const CENTURIES = [
  '2100 or future',
  '2000',
  '1900',
  '1800',
  '1700',
  '1600',
  '1500',
  '1400',
  '1300',
  '1200',
  '1100',
  '1000',
  '900',
  '800',
  '700',
  '600',
  '500',
  '400',
  '300',
  '200',
  '100',
  'BC',
  'Other',
];
export const DECADES = ['90', '80', '70', '60', '50', '40', '30', '20', '10'];

export const GENRES = [
  'Novel',
  'Fantasy',
  'Adventure',
  'Romance',
  'Contemporary',
  'Mystery',
  'Horror',
  'Thriller',
  'Paranormal',
  'Cooking',
  'Development',
  'Health',
  'History',
  'Travel',
  'Families & Relationships',
  'Humor',
  'Children’s',
];

export const TIMES = ['1900s', '2000s'];

export const SCREEN_ADAPTATIONS = [
  'All',
  'Movie',
  'TV series',
  'Documentary',
  'Short film',
  'Animation movie',
];
