<template>
  <div class="alert alert-success" role="alert">
    <p class="mb-0" v-text="message"></p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: relative;
  padding: 1.25rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #234e52;
  background-color: #e6fffa;
}
</style>
