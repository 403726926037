<template>
  <div class="tag_wrapper">
    <span class="tag__text">{{ text }}</span>
    <i class="tag__close" @click="onDelete"></i>
  </div>
</template>

<script>
export default {
  name: 'BasicTag',
  props: {
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  methods: {
    onDelete() {
      this.$emit('deleteItem', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
