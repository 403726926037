<template>
  <input
    type="text"
    :value="value"
    :autocomplete="autocomplete"
    @input="$emit('input', $event.target.value)"
    @focus="$emit('focus')"
    @blur="$emit('deactivate')"
  />
</template>

<script>
export default {
  name: 'TextField',

  props: {
    autocomplete: {
      type: String,
      default: 'off',
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
