<template>
  <section class="rate--information">
    <div class="row">
      <div class="col-12">
        <h3>{{ $t('About the book') }}</h3>
      </div>
      <div class="col-12 col-md-6">
        <p class="mb-4">{{ $t('add-information-text') }}</p>
        <!--        <a-->
        <!--          href="#"-->
        <!--          class="btn btn&#45;&#45;tertiary leading-loose mb-5 shadow"-->
        <!--          data-ui-modal-toggle="readtheplot"-->
        <!--          v-text="$t('I don\'t remember everything')"-->
        <!--        ></a>-->
      </div>
      <div class="col-12 col-md-6">
        <multiselect-with-validation
          id="search-century"
          :ref="$t('Centuries')"
          v-model="form.centuries"
          label="name"
          :name="$t('Select a centuries')"
          :placeholder="$t('Select century')"
          :validation-field="$t('Century')"
          :tag-placeholder="$t('Press enter to create a new event')"
          track-by="name"
          :options="centuries"
          :multiple="true"
          :taggable="true"
        >
          <label
            slot="label"
            for="search-century"
            class="form-label t-medium t-bold mb-2 pl-2"
            v-text="$t('Centuries')"
          ></label>
        </multiselect-with-validation>

        <multiselect-with-validation
          id="search-decades"
          :ref="$t('Decades')"
          v-model="form.decades"
          label="name"
          :name="$t('Select a decades')"
          :placeholder="$t('Select decade')"
          :validation-field="$t('Decade')"
          :tag-placeholder="$t('Press enter to create a new event')"
          track-by="name"
          :options="decades"
          :multiple="true"
          :taggable="true"
        >
          <label
            slot="label"
            for="search-decades"
            class="form-label t-medium t-bold mb-2 pl-2"
            v-text="$t('Decades')"
          ></label>
        </multiselect-with-validation>

        <div class="form-group tags mx-auto place__wrapper">
          <label for="place" class="form-label t-medium t-bold mb-2 pl-2">
            {{ $t('Place') }}
          </label>
          <gmap-autocomplete
            id="place"
            class="field-map__input form-group"
            :value="place"
            @input="changePlace"
            @place_changed="setPlace"
          >
          </gmap-autocomplete>
          <div class="places__list">
            <basic-tag
              v-for="pl in form.places"
              :id="pl"
              :key="pl"
              :text="pl"
              @deleteItem="removePlace"
            ></basic-tag>
          </div>
          <!--          <text-field-with-validation-->
          <!--            id="place"-->
          <!--            :ref="$t('Place')"-->
          <!--            v-model="form.place"-->
          <!--            :disabled="!hasEnoughReview"-->
          <!--            :rules="isRequiredField"-->
          <!--            :name="$t('Place')"-->
          <!--            type="text"-->
          <!--            :placeholder="$t('Place')"-->
          <!--          ></text-field-with-validation>-->
        </div>

        <multiselect-with-validation
          id="tag"
          :ref="$t('Tags')"
          v-model="form.tags"
          :options="tags"
          :multiple="true"
          label="name"
          :taggable="true"
          track-by="id"
          :validation-rules="isRequiredField"
          :placeholder="$t('Select tag')"
          :tag-placeholder="$t('Press enter to create a new tag')"
          :validation-field="$t('Tags')"
          @tag="addTag"
        >
          <label
            slot="label"
            for="tag"
            class="form-label t-medium t-bold mb-2 pl-2"
            v-text="$t('Tags')"
          ></label>
        </multiselect-with-validation>

        <multiselect-with-validation
          id="characters"
          :ref="$t('Characters\' names')"
          v-model="form.characterNames"
          :options="characterNames"
          :multiple="true"
          label="name"
          :taggable="true"
          track-by="id"
          :validation-rules="isRequiredField"
          :placeholder="$t('Select character')"
          :tag-placeholder="$t('Press enter to create a new character')"
          :validation-field="$t('Characters\' names')"
          @tag="addCharacter"
        >
          <label
            slot="label"
            for="characters"
            class="form-label t-medium t-bold mb-2 pl-2"
            v-text="$t('Characters\' names')"
          ></label>
        </multiselect-with-validation>

        <div class="form-group tags mx-auto">
          <label for="Nationality" class="form-label t-medium t-bold mb-2 pl-2"
            >{{ $t("Author's nationality") }}
          </label>
          <nationality-select
            id="Nationality"
            :ref="$t('Nationality')"
            v-model="form.authorsNationality"
            :value="form.authorsNationality"
            :options="nationalities"
            :placeholder="$t('Select nationality')"
            :validation-field="$t('Nationality')"
          />
        </div>
        <div class="row ml-2 mb-1">
          <basic-checkbox
            v-model="form.isTrueEvents"
            :label="$t('True events')"
            value="includeTrueEvents"
          ></basic-checkbox>
          <img
            v-tooltip.right="
              $t(
                'Click this box if the book refers to a true event and type it below'
              )
            "
            src="@/assets/img/info.png"
            class="mx-1 info_icon"
            alt="info"
          />
        </div>
        <multiselect-with-validation
          id="event"
          :ref="$t('True events')"
          v-model="form.trueEvents"
          :options="trueEvents"
          :multiple="true"
          label="name"
          :taggable="true"
          track-by="id"
          :validation-rules="isRequiredField"
          :placeholder="$t('Select event')"
          :tag-placeholder="$t('Press enter to create a new event')"
          :validation-field="$t('True events')"
          @tag="addTrueEvent"
        >
          <label
            slot="label"
            for="event"
            class="form-label t-medium t-bold mb-2 pl-2"
            v-text="$t('True events')"
          ></label>
        </multiselect-with-validation>
        <div class="row ml-2 mb-1">
          <basic-checkbox
            v-model="form.isRealCharacters"
            :label="$t('Real characters')"
            value="includeRealCharacters"
          ></basic-checkbox>
          <img
            v-tooltip.right="
              $t(
                'Click this box if the book refers to a real character and type it below'
              )
            "
            src="@/assets/img/info.png"
            class="mx-1 info_icon"
            alt="info"
          />
        </div>
        <multiselect-with-validation
          id="realCharacters"
          :ref="$t('Real characters')"
          v-model="form.realCharacters"
          j
          :options="realCharacters"
          :multiple="true"
          label="name"
          :taggable="true"
          track-by="id"
          :validation-rules="isRequiredField"
          :placeholder="$t('Select real character')"
          :tag-placeholder="$t('Press enter to create a new character')"
          :validation-field="$t('Real characters')"
          @tag="addRealCharacter"
        >
          <label
            slot="label"
            for="realCharacters"
            class="form-label t-medium t-bold mb-2 pl-2"
            v-text="$t('Real characters')"
          />
        </multiselect-with-validation>
      </div>
    </div>
  </section>
</template>

<script>
import MultiselectWithValidation from '@/components/validation/MultiselectWithValidation';
import NationalitySelect from '@/components/nationality/NationalitySelect';
import { CENTURIES, DECADES } from '@/views/home/searchAdvance/constants';
import BasicCheckbox from '@/components/ui/basic-checkbox/BasicCheckbox';
import BasicTag from '@/components/ui/basicTag/BasicTag';
import helpers from '../../../../helpers/helpers';

export default {
  components: {
    BasicTag,
    BasicCheckbox,
    // TextFieldWithValidation,
    MultiselectWithValidation,
    NationalitySelect,
  },

  props: {
    hasEnoughReview: {
      type: Boolean,
      default: false,
    },
    isGoogleBook: {
      type: Boolean,
      default: false,
    },
    book: {
      type: Object,
      required: true,
    },
    nationalities: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const centuries = CENTURIES.map((name) => ({
      name: this.$t(name),
      id: name,
    }));
    const decades = DECADES.map((name) => ({
      name: this.$t(name),
      id: name,
    }));

    return {
      form: {
        authorsNationality: '',
        times: [],
        centuries: [],
        decades: [],
        author: ['author'],
        places: [],
        tags: [],
        characterNames: [],
        realCharacters: [],
        trueEvents: [],
        isTrueEvents: '',
        isRealCharacters: '',
      },
      showInfoTrueEvents: false,
      showInfoRealCharacters: false,
      times: [],
      place: '',
      tags: [],
      characterNames: [],
      authorsNationality: [],
      trueEvents: [],
      realCharacters: [],
      centuries,
      decades,
    };
  },

  computed: {
    isRequiredField() {
      return this.hasEnoughReview ? 'required' : '';
    },
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', {
          ...this.form,
          tags: this.getNamesFomForm(this.form.tags),
          decades: this.getNamesFomForm(this.form.decades),
          centuries: this.getNamesFomForm(this.form.centuries),
          characterNames: this.getNamesFomForm(this.form.characterNames),
          trueEvents: this.getNamesFomForm(this.form.trueEvents),
          realCharacters: this.getNamesFomForm(this.form.realCharacters),
        });
      },
    },

    book: {
      immediate: true,
      handler() {
        this.updateForm();
      },
    },
  },

  mounted() {
    this.form = {
      authorsNationality: '',
      decades: this.formatFormInitialValue('Decades'),
      centuries: this.formatFormInitialValue('Centuries'),
      author: ['author'],
      places: this.book.places || [],
      tags: this.formatFormInitialValue('Tags'),
      characterNames: this.formatFormInitialValue('CharacterNames'),
      realCharacters: this.formatFormInitialValue('RealCharacters'),
      trueEvents: this.formatFormInitialValue('TrueEvents'),
    };
  },

  methods: {
    formatFormInitialValue(fieldName) {
      const { book } = this;
      if (book) {
        const field = book[fieldName];
        if (field && typeof field === 'string') {
          return field
            .split(',')
            .map((item) => ({ id: item, name: this.$t(item) }));
        }

        if (field && Array.isArray(field)) {
          return field.map((item, i) => {
            if (typeof item === 'string') {
              return { name: item, id: `item_${i}` };
            }
            return { name: item.name, id: item.id };
          });
        }
        return [];
      }
      return [];
    },

    setPlace(event) {
      const { address_components: components } = event;
      const country = components.find(({ types }) => types.includes('country'));
      const postalTown = components.find(({ types }) =>
        types.includes('locality')
      );

      const countryName = country ? country.long_name : '';
      const postalTownName = postalTown ? postalTown.long_name : '';
      this.form.places.push(`${postalTownName} ${countryName}`);
      this.place = `${postalTownName} ${countryName}`;
    },
    removePlace(id) {
      const index = this.form.places.findIndex((pl) => pl === id);
      this.form.places.splice(index, 1);
    },
    changePlace(event) {
      const { value } = event.target;
      this.place = value;
    },

    getNamesFomForm(property) {
      return property.map((el) => el.name);
    },

    addTag(newTag) {
      const tag = {
        name: helpers.toFirstUpperCase(newTag),
        id: newTag,
      };
      this.tags.push(tag);
      this.form.tags.push(tag);
    },

    addCharacter(newCharacter) {
      const character = {
        name: helpers.toFirstUpperCase(newCharacter),
        id: newCharacter,
      };
      this.characterNames.push(character);
      this.form.characterNames.push(character);
    },

    addTrueEvent(newEvent) {
      const event = {
        name: helpers.toFirstUpperCase(newEvent),
        id: newEvent,
      };
      this.trueEvents.push(event);
      this.form.trueEvents.push(event);
    },

    addRealCharacter(newRealCharacter) {
      const realCharacter = {
        name: helpers.toFirstUpperCase(newRealCharacter),
        id: newRealCharacter,
      };
      this.realCharacters.push(realCharacter);
      this.form.realCharacters.push(realCharacter);
    },

    updateForm() {
      return Object.keys(this.form).map((property) => {
        return { [property]: this.book[property] || this.form[property] };
      });
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './styles.scss';

.place__wrapper {
  display: flex;
  flex-direction: column;
}

.field-map__input {
  min-height: 49px;
  padding: 0 40px 0 32px;
  vertical-align: middle;
  border-radius: 24px;
  outline: none;
  margin: 0;
  border: 2px solid #e8e8e8;
}

.pac-item {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
</style>
