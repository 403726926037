<template>
  <load-component :load="loadNecessaryData">
    <div slot="success">
      <section class="tight_theme_bgr d-flex md-5 align-items-center">
        <div class="container">
          <h2 class="t-white t-center" v-text="$t('Add book')" />
        </div>
      </section>
      <div class="container">
        <div class="row">
          <validation-observer
            v-slot="{ handleSubmit }"
            tag="div"
            class="w-100"
          >
            <form
              class="col-12 col-xl-10 mt-5"
              @submit.prevent="handleSubmit(createBook)"
            >
              <h2 class="t-underlined mb-5" v-text="$t('About the book')" />
              <success-message
                v-if="response && response.isSuccessful()"
                :message="$t('Profile has been updated successfully')"
              />
              <error-message
                v-if="response && response.isError()"
                :message="$t('Could not find email')"
              />
              <section class="rate--information">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <text-field-with-validation
                        id="book_title"
                        v-model="form.title"
                        rules="required"
                        :label="$t('Title of the book')"
                        :name="$t('Title of the book')"
                        :placeholder="$t('Enter title')"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <text-field-with-validation
                        id="first_name"
                        v-model="form.first_name"
                        rules="required"
                        :label="$t('First Name')"
                        :name="$t('First Name')"
                        :placeholder="$t('Enter First Name')"
                      />
                    </div>
                    <div class="form-group">
                      <text-field-with-validation
                        id="last_name"
                        v-model="form.last_name"
                        rules="required"
                        :label="$t('Last Name')"
                        :name="$t('Last Name')"
                        :placeholder="$t('Enter Last Name')"
                      />
                    </div>
                    <div class="form-group">
                      <text-field-with-validation
                        id="publisher"
                        v-model="form.publisher"
                        rules="required"
                        :label="$t('Publisher')"
                        :name="$t('Publisher')"
                        :placeholder="$t('Enter Publisher')"
                      />
                    </div>
                    <span class="form-label">{{ $t('Publish year') }}</span>
                    <div class="row">
                      <div class="col-12 col-md-12">
                        <div class="form-group">
                          <calendar
                            :placeholder="$t('Publish year')"
                            :value="form.date_of_birth"
                            :name="$t('Datepicker')"
                            rules="required"
                            @input="selectDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <text-field-with-validation
                        id="pages"
                        v-model="form.pages"
                        rules="required|integer"
                        :label="$t('Pages')"
                        :name="$t('Pages')"
                        :placeholder="$t('Enter pages')"
                      />
                    </div>
                    <div class="form-group">
                      <text-field-with-validation
                        id="isbn"
                        v-model="form.isbn"
                        rules="required|integer"
                        :label="$t('isbn')"
                        :name="$t('isbn')"
                        :placeholder="$t('Enter isbn')"
                      />
                    </div>
                    <div class="form-group tags mx-auto">
                      <label for="search-genre" class="form-label d-block">
                        <span class="d-block t-left t-small mb-1">
                          {{ $t('Literary genre') }}
                        </span>
                        <div class="form-select">
                          <multiselect-with-validation
                            id="search-genre"
                            v-model="form.genre"
                            :placeholder="$t('Literary genre')"
                            :validation-field="$t('Literary genre')"
                            :tag-placeholder="
                              $t('Press enter to select a genre')
                            "
                            validation-rules="required"
                            :options="genres"
                            :multiple="true"
                            :taggable="true"
                            @tag="addGenre"
                          />
                        </div>
                      </label>
                    </div>
                    <ValidationProvider
                      v-slot="{ errors }"
                      class="form-group"
                      :name="$t('Comment')"
                      mode="eager"
                      rules="max:500|required"
                      tag="div"
                    >
                      <label for="comment" class="form-label">
                        {{ $t('Your comment:') }}
                      </label>
                      <textarea
                        id="comment"
                        v-model="form.book_comment"
                        class="form-control"
                        name="comment"
                        rows="3"
                        maxlength="500"
                        :placeholder="$t('Add your comment here')"
                      ></textarea>
                      <validation-error class="ml-2 mt-1" :errors="errors" />
                    </ValidationProvider>
                  </div>
                  <div class="col-12 col-md-6">
                    <ImageUploaderWithValidation
                      :placeholder="$t('image_description')"
                      :name="$t('Image')"
                      rules="image"
                      :value="form.image.name"
                      :max-size="5"
                      @input="addImage"
                    />
                  </div>
                </div>
              </section>
              <hr class="my-5" />
              <description
                :nationalities="nationalities"
                :book="book"
                :has-enough-review="true"
                @update="updateForm"
              />
              <hr class="my-5" />
              <rating :book="book" @update="updateForm" />
              <hr class="my-5" />
              <button
                class="btn btn--primary d-block d-lg-flex mx-auto mt-3 mb-5"
                v-text="$t('Add a book')"
              />
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
    <div slot="error"></div>
  </load-component>
</template>

<script>
import LoadComponent from '@/components/loadComponent/LoadComponent';
import SuccessMessage from '@/components/alerts/SuccessMessage';
import ErrorMessage from '@/components/alerts/ErrorMessage';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Calendar from '@/components/calendar/Calendar';
import ValidationError from '@/components/validation/ValidationError';
import TextFieldWithValidation from '@/components/validation/TextFieldWithValidation';
import MultiselectWithValidation from '@/components/validation/MultiselectWithValidation';
import nationalityMixin from '@/mixins/nationality';
import { mapGetters } from 'vuex';
import Description from '../../reviews/create/description/Description';
import Rating from '../../reviews/create/rating/Rating';
import ImageUploaderWithValidation from '../../../components/validation/uploadImage/ImageUploaderWithValidation';

export default {
  name: 'CreateBook',
  components: {
    ImageUploaderWithValidation,
    Rating,
    Description,
    TextFieldWithValidation,
    LoadComponent,
    SuccessMessage,
    ErrorMessage,
    ValidationObserver,
    ValidationProvider,
    Calendar,
    MultiselectWithValidation,
    ValidationError,
  },
  mixins: [nationalityMixin],
  data() {
    return {
      form: {
        title: '',
        first_name: '',
        last_name: '',
        publisher: '',
        date_of_birth: '',
        pages: '',
        isbn: '',
        genre: [],
        book_comment: '',
        image: '',
      },
      book: {},
      genres: [],
      response: null,
    };
  },
  computed: {
    ...mapGetters({
      nationalities: 'nationalities/nationalities',
    }),
  },
  methods: {
    async loadNecessaryData() {
      if (!this.nationalities.length) {
        await this.getNationalities();
      }
    },
    createBook() {},
    selectDate(value) {
      this.form.date_of_birth = value;
    },
    addGenre() {},
    updateForm(data = {}) {
      this.form = Object.assign(this.form, data);
    },
    addImage(value) {
      this.form.image = value;
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>
