<template>
  <div class="custom_select">
    <vue-multiselect
      v-bind="$attrs"
      :hide-selected="true"
      :show-labels="false"
      :show-no-results="true"
      v-on="$listeners"
    >
      <span
        slot="caret"
        slot-scope="{ toggle }"
        class="multiselect__arrow"
        @mousedown.prevent.stop="toggle"
      ></span>
      <span slot="noResult" class="t-italic">{{
        $t('No elements found')
      }}</span>
      <span slot="noOptions" class="t-italic">{{ $t('List is empty') }}</span>
      <span slot="placeholder">{{ $t('Select option') }}</span>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </vue-multiselect>
  </div>
</template>
<i18n src="./translations.json"></i18n>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  components: {
    VueMultiselect,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
