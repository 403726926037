<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="form-group"
    :name="name"
    :rules="rules"
    tag="div"
  >
    <span class="form-label">{{ $t('Book image') }}</span>
    <p>
      <small>{{ placeholder }}</small>
    </p>
    <section
      class="
        upload
        d-flex
        align-items-center
        justify-content-center
        flex-column
        upload_img
      "
    >
      <label for="book-cover-img">
        <img
          class="mb-2 upload_image"
          src="@/assets/img/upload-cloud.png"
          alt="Upload"
        />
        <h4 class="t-center">{{ $t('Upload files here') }}</h4>
        <input
          id="book-cover-img"
          type="file"
          class="upload__file"
          title="Upload File"
          @change="addFile"
        />
        <div v-if="file.length > 0">
          <img :src="file" alt="image" />
        </div>
        <p>
          <small class="t-small">
            {{ $t('max_size{size}', { size: maxSize }) }}
          </small>
        </p>
        <button class="btn btn--secondary d-none">
          {{ $t('Upload file') }}
        </button>
      </label>
    </section>
    <slot name="error">
      <validation-error
        class="ml-2 mt-1"
        :errors="[...errors, ...customErrors]"
      />
    </slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import ValidationError from '../ValidationError';

export default {
  name: 'ImageUploaderWithValidation',
  components: {
    ValidationError,
    ValidationProvider,
  },
  props: {
    value: {
      type: [File, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'eager',
    },
    maxSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      file: '',
      customErrors: [],
    };
  },
  methods: {
    addFile(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        const file = input.files[0];
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > this.maxSize) {
          this.customErrors = [
            ...this.customErrors,
            `Failed to upload an image. The image maximum size is ${this.maxSize}MB.`,
          ];
          return;
        }
        reader.onload = (e) => {
          this.customErrors = [];
          this.file = e.target.result;
          this.$emit('input', file);
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style>
@import './uploadImage.scss';
</style>
