<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="form-group"
    :name="validationField"
    :mode="validationMode"
    :rules="validationRules"
    tag="div"
  >
    <slot name="label"></slot>
    <template>
      <multiselect
        v-model="selected"
        :value="value"
        v-bind="$attrs"
        :custom-label="customLabel"
        label="label"
        v-on="$listeners"
      >
        <template slot="option" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ props.option }}</span>
          </span>
        </template>
      </multiselect>
    </template>
    <slot name="error">
      <validation-error
        :v-if="errors"
        class="ml-2 mt-1 error-message"
        :errors="errors"
      />
    </slot>
  </ValidationProvider>
</template>

<script>
import Multiselect from '@/components/ui/multiselect/Multiselect';
import { ValidationProvider } from 'vee-validate';
import ValidationError from '@/components/validation/ValidationError';

export default {
  components: {
    Multiselect,
    ValidationError,
    ValidationProvider,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    validationField: {
      type: [String],
      default: undefined,
    },

    validationRules: {
      type: String,
      default: '',
    },

    validationMode: {
      type: String,
      default: 'aggressive',
    },
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },
  },

  methods: {
    customLabel(label) {
      return this.$t(label.name);
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>
