<template>
  <div class="rating">
    <template v-for="n in stars">
      <input
        :id="`star-${name}-${n}`"
        :key="`input-${name}-${n}`"
        type="radio"
        :name="name"
        :disabled="disabled"
        :value="n"
        :checked="value === n"
        @change="$emit('change', n)"
      />
      <label :key="`label-${name}-${n}`" :for="`star-${name}-${n}`"></label>
    </template>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    value: {
      type: Number,
      required: true,
    },

    number: {
      type: Number,
      default: 5,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    stars() {
      return [...Array(this.number).keys()]
        .slice()
        .reverse()
        .map((n) => n + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
